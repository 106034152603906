// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateLocationBrochureRequest from "./requests/CreateLocationBrochureRequest.res.js";
import * as UpdateLocationBrochureRequest from "./requests/UpdateLocationBrochureRequest.res.js";
import * as DestroyLocationBrochureRequest from "./requests/DestroyLocationBrochureRequest.res.js";
import * as ActivateLocationBrochureRequest from "./requests/ActivateLocationBrochureRequest.res.js";
import * as DeactivateLocationBrochureRequest from "./requests/DeactivateLocationBrochureRequest.res.js";
import * as FetchLocationBrochuresIndexRequest from "./requests/FetchLocationBrochuresIndexRequest.res.js";
import * as FetchLocationBrochureLocationsRequest from "./requests/FetchLocationBrochureLocationsRequest.res.js";
import * as NotifyLocationBrochureDownloadRequest from "./requests/NotifyLocationBrochureDownloadRequest.res.js";
import * as FetchLocationBrochuresDashboardRequest from "./requests/FetchLocationBrochuresDashboardRequest.res.js";
import * as ActivateLocationBrochuresInBatchRequest from "./requests/ActivateLocationBrochuresInBatchRequest.res.js";

var create = CreateLocationBrochureRequest.exec;

var update = UpdateLocationBrochureRequest.exec;

var destroy = DestroyLocationBrochureRequest.exec;

var dashboard = FetchLocationBrochuresDashboardRequest.exec;

var activate = ActivateLocationBrochureRequest.exec;

var deactivate = DeactivateLocationBrochureRequest.exec;

var activateInBatch = ActivateLocationBrochuresInBatchRequest.exec;

var locations = FetchLocationBrochureLocationsRequest.exec;

var notifyDownload = NotifyLocationBrochureDownloadRequest.exec;

var index = FetchLocationBrochuresIndexRequest.exec;

export {
  create ,
  update ,
  destroy ,
  dashboard ,
  activate ,
  deactivate ,
  activateInBatch ,
  locations ,
  notifyDownload ,
  index ,
}
/* CreateLocationBrochureRequest Not a pure module */
