// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as $$Location from "../../../models/Location.res.js";

function exec(providerId) {
  return Rest.$$fetch("/dashboard/location_brochures/providers/" + ID.toString(providerId) + "/locations", "Get", {
              NAME: "Json",
              VAL: $$Location.FilterList.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
