// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as LocationBrochure from "../../../models/LocationBrochure.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

var Req = {
  SortDirection: SortDirection,
  SortBy: SortBy
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              locationBrochures: field.required("locationBrochures", Json_Decode$JsonCombinators.array(LocationBrochure.Dashboard.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalLocationBrochures: field.required("totalLocationBrochures", Json_Decode$JsonCombinators.$$int)
            };
    });

var Res = {
  decoder: decoder
};

function exec(pageNum, query, sortBy, providerIds) {
  var page = "page=" + String(pageNum);
  var providers = providerIds !== undefined && providerIds.length !== 0 ? "&" + Belt_Array.map(providerIds, (function (id) {
              return "provider_ids[]=" + ID.toString(id);
            })).join("&") : "";
  var query$1 = Belt_Option.getWithDefault(Belt_Option.map(query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "Active" ? "&sort_by=location_brochures.active&sort_direction=" + toString(sortBy.VAL) : (
      variant === "ProviderName" ? "&sort_by=providers.name&sort_direction=" + toString(sortBy.VAL) : (
          variant === "LocationName" ? "&sort_by=locations.name&sort_direction=" + toString(sortBy.VAL) : (
              variant === "Downloads" ? "&sort_by=location_brochures.downloads_count&sort_direction=" + toString(sortBy.VAL) : (
                  variant === "Title" ? "&sort_by=location_brochures.title&sort_direction=" + toString(sortBy.VAL) : "&sort_by=location_brochures.ahoy_events_count&sort_direction=" + toString(sortBy.VAL)
                )
            )
        )
    );
  return Rest.$$fetch("/dashboard/location_brochures?" + (page + (providers + (query$1 + sortBy$1))), "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
