// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as LocationBrochure from "../../../models/LocationBrochure.res.js";

function exec(pageNum, sortBy, query, providerIds) {
  var page = "page=" + String(pageNum);
  var providers = providerIds !== undefined && providerIds.length !== 0 ? "&" + (function (__x) {
          return Js_array.joinWith("&", __x);
        })(Belt_Array.map(providerIds, (function (id) {
                return "provider_ids[]=" + ID.toString(id);
              }))) : "";
  var query$1 = Belt_Option.getWithDefault(Belt_Option.map(query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var sortBy$1;
  if (sortBy !== undefined) {
    var variant = sortBy.NAME;
    sortBy$1 = variant === "Title" ? "&sort_by=location_brochures.title&sort_direction=" + LocationBrochure.SortDirection.toString(sortBy.VAL) : (
        variant === "Location" ? "&sort_by=locations.name&sort_direction=" + LocationBrochure.SortDirection.toString(sortBy.VAL) : "&sort_by=providers.name&sort_direction=" + LocationBrochure.SortDirection.toString(sortBy.VAL)
      );
  } else {
    sortBy$1 = "";
  }
  return Rest.$$fetch("/location_brochures?" + page + providers + sortBy$1 + query$1, "Get", {
              NAME: "Json",
              VAL: LocationBrochure.Index.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
